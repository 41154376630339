import axios from "axios";
import axiosAuth from '../../main'

const state = {
    flightArr: [],
};
// getters get data from the state
// Getters are computed properties
// syncronous
const getters = {
    getFlights: state => state.flightArr,
    // getPostById: (state) => (id) => {
    //     return state.postArr.find(post => post._id === id)
    // }
};
//mutations update the state
//convention to use uppercase letters for mutations
const mutations = {

    SET_FLIGHTS(state, data) {
        state.flightArr = data;
    }

};

const actions = {

    async scheduleFlight({ dispatch }, data) {
        console.log('schedule: ', data);
        // await axios.post(`/post`, data, header)
        // await axiosAuth.post(`/post`, data)
        // .then(resp => {
            // console.log(resp);
        // })
        // refresh all posts into state, to include new post
        // await dispatch('listPosts')
        // console.log('create post success')
    },

    // async listPosts( { commit } ) {
    //     const posts = await axios.get(`/post/all`)
    //     commit('SET_POSTS', posts.data)
    //     return posts.data
    // },

    // async updatePost( { dispatch }, data) {
    //     try{
    //         const { url, body } = data;
    //         const r = await axios.put( url, body );
    //         await dispatch('listPosts')
    //         return r.data;
    //     } catch(e) {
    //         console.warn('GetById Error: ', e)
    //     }
    // },

    // async readSinglePost( {state}, pid ) {
    //     console.log(state)
    //     const post = await axios.get(`/blog/post/${pid}` )
    //     // console.log(post.data)
    //     return post.data.post
    // },


};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};