<template>
  <div>
      <router-view v-slot="{ Component }">
        <!-- <transition name="slide"  enter-active-class="animate__animated animate__slideInRight"  leave-active-class="animate__animated animate__slideOutLeft"> -->
        <!-- <transition name="slide" enter-active-class="animated slideInRight delay" leave-active-class="animated slideOutLeft"> -->

        <transition >
        <!-- enter-active-class='animated v-enter-active'  -->
        <!-- leave-active-class='animated v-leave-active'> -->
          <component :is="Component" />
        </transition>
      </router-view>

  </div>
</template>
<script>
export default {
  components: {},
}
</script>
