<template>
  <v-container fluid>

    <v-row class=" d-flex align-top flex-sm-row flex-column">
      <col-single class='form-new-fund'>

        <card-main>
        <!-- <v-card
          class="pa-sm-2 pa-xl-4 mx-lg-auto"
          variant="outlined"
          width="100%"
          style="border-radius: 16px"
        > -->

          <card-title title='Create New Crypto Crowd Fund'></card-title>

            <v-form @submit="onSubmit" >

              <v-text-field
                placeholder="Fun Name"
                name="name"
                label='Name'
                v-model="name"
                variant="contained"
                density="comfortable"
                clearable
                :error-messages='errors.name'
              />

              <v-text-field
                placeholder="Catchy Slogan..."
                name="slogan"
                label='Slogan'
                v-model="slogan"
                variant="contained"
                density="comfortable"
                clearable
                :error-messages='errors.slogan'
              />

              <v-text-field
                placeholder="Fund Description"
                name="description"
                label='Description'
                v-model="description"
                variant="contained"
                density="comfortable"
                clearable
                :error-messages='errors.description'
              />

              <v-text-field
                placeholder="Crypto Address"
                name="address"
                label='Your Crypto Address'
                v-model="address"
                variant="contained"
                density="comfortable"
                clearable
                :error-messages='errors.address'
              />


              <v-btn rounded color='secondary' xs-small class='ma-2' type='submit'>Submit</v-btn>

            </v-form>
        </card-main>
        <!-- </v-card> -->
      </col-single>



    </v-row>    
  </v-container>
</template>
<script>
import { useStore } from 'vuex'
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
export default {
  setup() {
    const store = useStore();

    const schema = yup.object({
      name: yup.string().required(),
      slogan: yup.string().required(),
      description: yup.string().required(),
      address: yup.string().required(),
    });

    const { handleSubmit, errors } = useForm({
      validationSchema: schema,
    });

    const { value: name } = useField("name");
    const { value: slogan } = useField("slogan");
    const { value: description } = useField("description");
    const { value: address } = useField("address");


    const onSubmit = handleSubmit(values => {
      store.dispatch('crowdfund/newCrowdFund', values);
    });

    return {
      name,
      slogan,
      description,
      address,
      onSubmit,
      errors,
    };
  },
};
</script>




