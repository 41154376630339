<template>
  <v-container>
    <v-row class="d-flex align-top flex-sm-row flex-column">

      <v-col>
        <div class="post-card">
          <card-main>
            <card-title
              title="Departure Date Range"
            ></card-title>

            <!-- <v-select v-model="flightType">
              <option value="one-way flight">One-way Flight</option>
              <option value="return flight">Return Flight</option>
            </v-select> -->
            <v-chip 
              class="mb-5 pt-10 my-auto"
              style='width: 100%;'
            >
              <v-select label="Select type of trip" variant='plain' 
              density='comfortable' v-model="select" :items="flightTypeOptions"></v-select>
            </v-chip>

              <v-select 
              label="Select Destination" 
              variant='plain' 
              density='comfortable' 
              v-model="selectedDest" :items="DestOptions"></v-select>
            <!-- <input type="date" v-model="departureDate" />
            <input type="date" v-model="returnDate" :disabled="!isReturn" />
            <button :disabled="!canBook" @click="book">Book</button> -->
            <v-te
            xt-field type="date" v-model="departureDate" />
            <v-text-field type="date" v-model="returnDate" :disabled="!isReturn" />
            <v-btn :disabled="!canBook" @click="book">Book</v-btn>

            <p>
              {{ canBook ? "" : "Return date must be after departure date." }}
            </p>
          </card-main>
        </div>
      </v-col>


      <v-col>
        <div class="post-card">
          <card-main>
            <card-title title="Or join and existing scheduled flight"></card-title>
          </card-main>
        </div>
      </v-col>

    </v-row>
  </v-container>
</template>
<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore()

const DestOptions = ["Mexico", "El Salvador"];
const selectedDest = ref("Mexico");

const flightTypeOptions = ["return flight", "one-way flight"];
const select = ref("one-way flight");
const flightType = ref("one-way flight");
const departureDate = ref(dateToString(new Date()));
const returnDate = ref(departureDate.value);
const isReturn = computed(() => flightType.value === "return flight");

const canBook = computed(() =>
    !isReturn.value ||
    stringToDate(returnDate.value) > stringToDate(departureDate.value)
);
function stringToDate(str) {
  const [y, m, d] = str.split("-");
  return new Date(+y, m - 1, +d);
}
function dateToString(date) {
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate())
  );
}
function pad(n, s = String(n)) {
  return s.length < 2 ? `0${s}` : s;
}

function book() {
    store.dispatch("flights/scheduleFlight", { 
       departDate: departureDate.value,
     } );
}

// function book() {
//   alert(
//     isReturn.value
//       ? `You have booked a return flight leaving on ${departureDate.value} and returning on ${returnDate.value}.`
//       : `You have booked a one-way flight leaving on ${departureDate.value}.`
//   );
// }
</script>

<style>
select,
input,
button {
  display: block;
  margin: 0.5em 0;
  font-size: 15px;
}

input[disabled] {
  color: #999;
}

p {
  color: red;
}
</style>