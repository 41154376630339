<template>
  <v-container fluid>
    <v-row v-for="fund in funds" :key="fund._id">
        <!-- <v-row> -->
      <col-single class="post-list">


    <!-- <v-card> -->
        <v-card
          class="pa-sm-2 pa-xl-4 mx-lg-auto"
          variant="outlined"
          width="80%"
          style="border-radius: 16px"
        >

     <div class="d-flex flex-no-wrap justify-space-between">
       <div>

         <v-card-header>
           <div>
             <div class="text-overline mb-1 text-secondary">{{ fund.created_date }}</div>
             <div class="text-h5 mb-1">{{ fund.name }}</div>
             <div class="text-h6 text-primary">{{ fund.slogan }}</div>
             <div class="text-caption">{{ fund.description }}</div>
             <div class="text-caption">{{ fund.address_created.address_in }}</div>

           </div>
         </v-card-header>

        <v-card-actions>
            <v-btn variant="outlined" >Edit</v-btn>
          </v-card-actions>
       </div>

              <v-avatar
                class="ma-3"
                size="185"
                rounded="0"
              >
                <v-img src="https://cdn.vuetifyjs.com/images/cards/halcyon.png"></v-img>
              </v-avatar>

     </div>

        </v-card>


      </col-single>

    </v-row>
  </v-container>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
  setup() {// dont need await
    const store = useStore();

    // store.dispatch("crowdfund/listFunds");
    const funds = computed(() => {
      return store.getters["crowdfund/getCrowdFunds"];
    });

    console.log("fund list: ", funds.value);
    return {
      funds,
    //   isAuthor,
    };
  },
};
</script>
