import axios from "axios";

  const state = {
    farmArr: [],
    lpTokenArr: [],

    farmHeaders: [],
    lpTokenHeaders: [],
    skip: 0,
    limit: 5,
    // totalPages: null,
    // currentPage: null,
  };
  // - gets data from the state
  // - computed properties
  // - syncronous
  const getters = {
    getHeaders: state => state.farmHeaders,
    farmGetter: state => state.farmArr,
    lpTokenGetter: state => state.lpTokenArr,
    getLpTokenHeaders: state => state.lpTokenHeaders,

    // pages: state => Math.trunc(state.totalPages),
  };
  // - updates state
  // - convention to use uppercase letters for mutations
  const mutations = {
    SET_FARMS(state, data) {
      state.farmArr = data;
      state.farmHeaders = Object.keys( data[0] ).slice(1);
    },

    SET_LP_TOKENS(state, data) {
      state.lpTokenArr = data;
      state.lpTokenHeaders = Object.keys( data[0] ).slice(1);
    },

    PAGE_UP(state) {
      state.skip = state.skip + state.limit;
    },
    PAGE_DOWN(state) {
      state.skip = state.skip - state.limit;
    },
    SET_LIMIT(state, incr) {
      state.limit = incr;
    },
    // SET_COUNT(state, int) {
      // state.totalPages = int / state.limit;
    // },
  };
  const actions = {
    // limit=5&skip=10
    // async farmAction( { commit } ) {
    //   commit('SET_COUNT', resp.data.count)
    //   const resp = await axios.get(`/farms?limit=${params.limit}&skip=${params.skip}`);
    //   console.log(resp.data)
    //   commit('SET_FARMS_UNION', resp.data.data)
    //   commit('SET_COUNT', resp.data.count)
    // },
    async farmAction( { state, commit } ) {
      const resp = await axios.get(`/farms?limit=${state.limit}&skip=${state.skip}`);
    //   console.log('farmAction: ', resp.data);
      commit('SET_FARMS', resp.data.data)
    },

    async lpTokenAction( { state, commit } ) {
      const resp = await axios.get(`/lp-tokens?limit=${state.limit}&skip=${state.skip}`);
    //   console.log('lp-tokens: ', resp.data);
      commit('SET_LP_TOKENS', resp.data.data)
    },

  };

  export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
  };

