<template>
	<v-container fluid>
		<v-row v-for="i in posts" :key="i._id">

			<col-single class="post-list">

				<v-card variant="outlined">
					<v-card :to="{
						name: 'post',
						params: { postId: i._id }
					}">
						<v-card-text>
							<card-header-blog :obj="i"></card-header-blog>
						</v-card-text>
					</v-card>

					<v-card-actions>
						<v-chip class="ma-2" color="secondary" label text-color="white">
							{{ i.category }}
						</v-chip>

						<div v-for="tag in i.tags" :key="tag">
							<v-chip class="ma-2" color="primary" label text-color="white">
								<v-icon start icon="mdi-label"></v-icon>
								{{ tag }}
							</v-chip>
						</div>
						<v-spacer></v-spacer>
						<!-- <v-icon class="mr-1" color='secondary' icon="mdi-heart"></v-icon>
						<v-icon class="mr-1" color='primary' icon="mdi-share-variant"></v-icon> -->
						<div v-if="isAuthor === i.author_id">
							<v-btn variant="outlined" icon="mdi-pencil" size='x-small' :to="{ name: 'editPost', params: { postId: i._id } }"></v-btn>
							<v-btn @click='deletePost(i._id)' variant="outlined" icon='mdi-delete' size='x-small' color='pink'></v-btn>
						</div>

						<v-spacer></v-spacer>

						<v-icon class="mr-1" color='secondary' icon="mdi-heart"></v-icon>
						<v-icon class="mr-1" color='primary' icon="mdi-share-variant"></v-icon>
					</v-card-actions>
				</v-card>

			</col-single>

		</v-row>
	</v-container>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";
export default {
	setup() {
		const store = useStore();

		const isAuthor = computed(() => {
			return store.getters["auth/getUserId"];
		});
		const posts = computed(() => {
			return store.getters["blog/getPosts"];
		});

		function deletePost(id) {
			// alert(message);
			store.dispatch("blog/deletePost", { url: `post/delete/${id}` });
			// const uurl = this.$BACK_ROUTES.postDelete + id
			// store.dispatch( "blog/deletePost", { url: `${uurl}` } );
		}

		return {
			posts,
			isAuthor,
			deletePost
		};
	},
};
</script>
