<template>
  <v-app color='primary'>
    <AppLayout>
      <template v-slot:content-slot>
        <router-view></router-view>
      </template>
    </AppLayout>
  </v-app>
</template>
<script >
import AppLayout from "@/AppLayout.vue";
import { useStore } from "vuex";
// import { useRouter } from 'vue-router'
// import { computed } from "vue";
export default {
  name: "App",
  components: { AppLayout },

  setup() {
    const store = useStore();
    store.dispatch("blog/listPosts");
    // store.dispatch("analytics/farmAction");
    // store.dispatch("analytics/lpTokenAction");
    // store.dispatch("crowdfund/listFunds");
  }

};
</script>
<style lang='scss'>
@import './sass/main.scss';
// @use './sass/main.scss';
</style>