// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// Vuetify
import { createVuetify } from 'vuetify'
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides


// const themeOne = {
//   dark: true,
//   colors: {
//     background: '#FFFFFF',
//     // background: '#010101',
//     // surface: '#FFFFFF',
//     surface: '#010101',
//     primary: '#6200EE',
//     'primary-darken-1': '#3700B3',
//     secondary: '#03DAC6',
//     'secondary-darken-1': '#018786',
//     error: '#B00020',
//     info: '#2196F3',
//     success: '#4CAF50',
//     warning: '#FB8C00',
//   }
// }
// export default createVuetify({
//   theme: {
//     defaultTheme: 'themeOne',
//     themes: {
//       themeOne,
//     }
//   }
// });
export default createVuetify({
  // display: {
  //   mobileBreakpoint: 'sm',
  //   thresholds: {
  //     xs: 0,
  //     sm: 340,
  //     md: 540,
  //     lg: 800,
  //     xl: 1280,
  //   },
  // },
  theme: {
    defaultTheme: 'dark'
  }
  // dark: true
  // theme: false
});
