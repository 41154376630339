
import routes from '../routes';
import { createRouter, createWebHashHistory } from 'vue-router';

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.protectedRoute)) {
        const loggedIn = !!localStorage.getItem("username");
        // console.log('loggedin: ', loggedIn);
        if (!loggedIn) {
            console.log(' not logged in')
            next({ name: 'Login' });
        } else {
            setMetaTags(to, from, next)
            next();
        }
    } else {
        // console.log('not protected')
        setMetaTags(to, from, next)
        next();
    }
});
// add advanced meta tags dynamically
// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
function setMetaTags(to, from, next) {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) {
        // return next();
        return;
    }
    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });
        // We use this to track which meta tags we create so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');
        return tag;
    })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
        
    // next();
    return;

}


export default router;





// router.beforeEach((to, from, next) => {
//     if (to.matched.some(record => record.meta.protectedRoute)) {
//         const loggedIn = !!localStorage.getItem("username");
//         // console.log('loggedin: ', loggedIn);
//         if (!loggedIn) {
//             console.log(' not logged in')
//             next({ name: 'Login' });
//         } else {
//             document.title = to.meta.title;
//             // console.log('logged in')
//             // if( from.name == 'Login' ) {
//             //     next({ name: 'editor' })
//             // } else {
//             //     next();
//             // }
//             next();
//         }
//     } else {
//         // console.log('not protected')
//         document.title = to.meta.title;
//         next();
//     }
// });
// export default router;


// router.beforeEach((to, from, next) => {
//     // This goes through the matched routes from last to first, finding the closest route with a title.
//     // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
//     // `/nested`'s will be chosen.
//     const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
//     // Find the nearest route element with meta tags.
//     const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
//     const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
//     // If a route with a title was found, set the document (page) title to that value.
//     if (nearestWithTitle) {
//         document.title = nearestWithTitle.meta.title;
//     } else if (previousNearestWithMeta) {
//         document.title = previousNearestWithMeta.meta.title;
//     }
//     // Remove any stale meta tags from the document using the key attribute we set below.
//     Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
//     // Skip rendering meta tags if there are none.
//     if (!nearestWithMeta) {
//         return next();
//     }
//     // Turn the meta tag definitions into actual elements in the head.
//     nearestWithMeta.meta.metaTags.map(tagDef => {
//         const tag = document.createElement('meta');
//         Object.keys(tagDef).forEach(key => {
//             tag.setAttribute(key, tagDef[key]);
//         });
//         // We use this to track which meta tags we create so we don't interfere with other ones.
//         tag.setAttribute('data-vue-router-controlled', '');
//         return tag;
//     })
//         // Add the meta tags to the document head.
//         .forEach(tag => document.head.appendChild(tag));
//     next();
// });
// export default router;
