<template>

    <!-- <v-toolbar color='primary'></v-toolbar> -->
    <v-card-title class='d-flex justify-center'>
        <!-- <v-card-title v-bind='$attrs' v-on='listeners'> -->
        <div class='text-primary' > {{ title }} </div>
      <!-- <div class='white d-flex justify-center' >EVM LP Tokens</div> -->
    </v-card-title>

</template>
<script>
export default {
    
    props: ['title'],
    setup(props) {
        
        // return {
            // title: props.title
        // }
    },
}
</script>
