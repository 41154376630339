<template>
    <v-container>
        <v-row >
        <v-col>
            <div class='post-card'>
            <!-- <div > -->
                <!-- <v-card > -->
                    <card-main>
                        <!-- <v-card-title> -->
                            <div class='m-4'>
                                <div class="text-overline ml-3 mb-1 text-secondary">{{ postObj.created_date }}</div>
                                <div class="text-h5 ml-3 mb-1">{{ postObj.title }}</div>
                                <div class="text-h6 ml-3 text-primary">{{ postObj.headline }}</div>
                                <!-- <div class="text-caption">{{ post.headline }}</div> -->
                            </div>
                        <!-- </v-card-title> -->
                        <!-- <card-header-blog :obj='postObj'></card-header-blog> -->
                        <v-card-text v-html="postBody"></v-card-text>
                    <!-- <v-card-text v-html="$MD(postObj.post)"></v-card-text> -->
                    <!-- <v-card-text v-html="$MD($route.params.post)"></v-card-text> -->
                <!-- </v-card> -->
                </card-main>
            </div>

            <!-- if user logged in they can see comments and post -->
            <div v-if='isAuth' class='post-card pt-4'>
                <card-main variant='outlined'>
                    <v-textarea color='primary' v-model='comment'></v-textarea>
                    <v-card-actions>
                        <v-btn xs-small class='ml-2' color='primary' variant='tonal' @click='submitComment'>Submit Comment</v-btn>
                    </v-card-actions>
                    
                    <v-divider class="mt-3"></v-divider>

                    <div v-for='item in postObj.comments' :key="item">
                        <v-card-item class="ma-4 pa-sm-2 pa-xl-4 mx-lg-auto" variant="outlined" width="75%" style="border-radius: 16px" >
                            <div class="text-overline text-secondary">{{ item.created_date }}</div>
                            <div class="text-h6 text-primary">{{ item.comment }}</div>
                        </v-card-item>
                    </div>
                </card-main>
            </div>

        </v-col>
    </v-row>
</v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import CardMain from '@/components/CardMain';

export default {
    components: { CardMain },
data() {
    return {
        pid: '',
        postObj: {},
        postBody: '',
        comment: '',
    }
},
computed: {
    ...mapGetters({
        // blogPost: 'blog/getPostById',
        currentUserID: 'auth/getUserId',
        isAuth: 'auth/isAuthenticated'
    }),
},
methods: {
    ...mapActions({
        readSinglePost: 'blog/readSinglePost'
    }),

    async getPost(post_id) {
        this.postObj = await this.readSinglePost(post_id)
        // this.postObj = await this.readSinglePost(this.$route.params.postId)
        // this.postObj = this.blogPost(this.$route.params.postId)
        console.log(this.postObj)
        this.postBody = this.$MD(this.postObj.post)
    },

    async submitComment() {
        // console.log(this.comment)
        const comment_user_id = await this.currentUserID
        // console.log(comment_user_id);
        if(this.comment != '') {
            this.$store.dispatch("blog/createPostV2", {
                url: `/post/comment/update/${this.postObj._id}`,
                body: { 
                    user_id: comment_user_id, 
                    comment: this.comment
                }
            });
            // update post on click
            this.getPost(this.pid);
            this.comment = ''
        }
    },
    // async submitComment() {
    //     const comment_user_id = await this.currentUserID
    //     this.$store.dispatch("blog/updatePost", {
    //       url: `/post/update/${this.postObj._id}`,
    //       body: {
    //         user_id: comment_user_id, 
    //         comment: this.comment
    //       },
    //     });
    // },

},
async mounted() {
    this.pid = this.$route.params.postId;
    this.getPost(this.pid);
    console.log('pid: ', this.pid);
},


//     async routeFunc(route) {
//         // Option 1. We can get post from server 
//         // const r = route.params.postId
//         this.postObj = await this.blogPost(route.params.postId)
//         // this.postObj = p;
//         // console.log('p: ', p)
//         // console.log(route.params)
//         // console.log('post: ', this.postObj)
//         // Option 2. 
//         // We can get post from route param (faster)
//         // this.post = route.params.post
//     }

// async created() {
    // console.log(this.$route.params.postId)
    // this.postObj = this.blogPost(this.$route.params.postId)
    // this.postObj = await this.readPost(this.$route.params.postId)
    // console.log(this.postObj)
    // await this.routeFunc(this.$route);
// },

// watch: {
//     $route(newRoute) { 
//         this.routeFunc(newRoute); 
//     }
// }
// watch(() => this.$route.params.postId, ( toParams, previousParams ) => {
//     console.log(toParams, previousParams )
//   })

};
</script>
