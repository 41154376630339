<template>
  <div class="text-center" app>
    <v-snackbar
      app
      rounded
      elevation='22'
      v-model="visible"
      :color='color'
      :timeout='timeout'>

      <p style='color: white;'>{{ content }} </p>
      <!-- <p style='text-color: {{ btnColor }};'>{{ content }} </p> -->
      <!-- <p >{{ content }} </p> -->

      <template v-slot:actions>
        <v-btn
          text
          dark
          :color="btnColor"
          variant="text"
          @click="clearSnack()">
          Close
        </v-btn>
      </template>

    </v-snackbar>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data: () => ({
      timeout: 2000,
  }),
  computed: {
    ...mapState([
      "content",
      "bottom",
      "color",
      "visible",
      "btnColor" ])
  },
  methods: {
    ...mapMutations({clearSnack: "CLEAR_SNACK" })
  }


};
</script>
