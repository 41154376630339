<template>
  <v-col
    cols="12"
    xs="12"
    sm="8"
    md="8"
    lg="4"
    xl="4"
    class="mx-auto d-flex justify-center"
  >
    <slot />
  </v-col>
</template>
<script>
// import { useDisplay } from 'vuetify'
// import { computed } from 'vue'
export default {
  setup() {
    // const { name } = useDisplay()
  },
};
</script>