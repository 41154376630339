<template>
    <v-container fluid>

        <v-row class="d-flex align-center flex-sm-row flex-column">
            <v-col>
                <v-card>
                    <card-title title='Search Custom Index'></card-title>
                    <v-card-text>
                        <v-row class="d-flex align-center flex-sm-row flex-column ">
                            <v-col>
                                <v-text-field type='string' name="Search" label="Search" variant='outlined'
                                    v-model="variablesOne.query" class='input-text-color' />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="8" class="mx-auto">
                <v-card>
                    <div v-if="loading">Loading...</div>
                    <div v-else-if="error">Error: {{ error.message }}</div>
                    <div v-else-if="(result && result.searchComIndex)">
                        <v-expansion-panels>
                            <v-expansion-panel v-for="item of comp" :key="item">
                                <v-expansion-panel-title>
                                    <!-- {{ $MD(item.com) }} -->
                                    <div v-html="item.sub">
                                    </div>

                                    <br/>
                                    <br/>
                                    <br/>
                                    <!-- <div v-html="item.com"></div> -->
                                    <v-card-text v-html="$MD(item.com)"></v-card-text>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <v-row v-for="reply in item.lastReplies" :key="reply">
                                        <v-col cols="4" class="d-flex justify-start">
                                            <v-card-text v-html="$MD(reply.com || '')"></v-card-text>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-text>
                            </v-expansion-panel>

                        </v-expansion-panels>
                    </div>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
<script setup>
import { ref, reactive } from 'vue'
import gql from 'graphql-tag'
import { useQuery } from '@vue/apollo-composable';
import { watchEffect, computed } from 'vue';

const variablesOne = reactive({
    query: "kanye",
    path: 'com',
    limit: 200
})
const SEARCH_CHAN_ONE = gql`
    query fullTextSearch($query: String!, $path: String!, $limit: Int = 2) {
        searchComIndex(query: $query, path: $path, limit: $limit) {
            capcode
            sub
            com
            lastReplies {
                com
            }
        }
    }
`
const { result, loading, error } = useQuery(SEARCH_CHAN_ONE, variablesOne);
const comp = computed(() => result.value?.searchComIndex ?? [])

watchEffect(() => {
    console.log(comp.value)
})

// function search_text (query) {
    // variables.query = query
// }
</script>