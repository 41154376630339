<template>  
  <v-form @submit="onSubmit" class='form-md-editor'>

      <v-text-field 
      name="title" 
      label="What's your post's title?"
      color='primary' 
      variant='outlined' 
      density='comfortable' 
      type='text'
      v-model="title"
      class='pa-2' />

      <v-text-field 
      name="headline" 
      label="A headline for your post to create interest."
      color='primary' 
      variant='outlined' 
      density='comfortable' 
      type='text'
      v-model="headline"
      class='pa-2'
      />

      <div class="editor">
        <textarea class="input" :value="input" @input="update"></textarea>
        <div class="output" v-html="$MD(output)"></div>
      </div>


      <v-btn @click='onSubmit'>Submit</v-btn>
      <!-- <v-btn rounded xs-small color='primary' class='ma-2' type="submit">Submit</v-btn> -->
  </v-form>
</template>
<script>
import { marked } from "marked";
import { debounce } from "lodash-es";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

export default {
setup() {
  const router = useRouter()
  const store = useStore()

  const title = ref('');
  const headline = ref('');
  const input = ref("# Title \n ___\n ```python \n import pandas as pd \n\n df = pd.read_csv('data.csv')\n```");

  const output = computed(() => marked(input.value));
  const update = debounce((e) => { input.value = e.target.value; }, 100);

  const onSubmit = (() => {
    if ( title.value != '' && headline.value != '' && input.value != '' ) {
      store.dispatch("blog/createPost", { 
        title: title.value,
        headline: headline.value, 
        post: input.value 
      } );

    //   router.push( { name: 'allPosts' } );

    } else {
      console.warn( 'Complete all fields' );
    }

  });

  return {
    title,
    headline,
    input,
    output,
    update,
    onSubmit,
  }

}
}

</script>
<style scoped>
</style>