import BaseView from '@/views/BaseView';
import SingleColView from '@/views/SingleColView';

import CryptoPage from '@/pages/CryptoPage';
import FlightBookPage from '@/pages/FlightBookPage';

import PostPage from "@/pages/PostPage"
import PostListPage from "@/pages/PostListPage"

import WebCam from '@/pages/WebCam';
// crowdfunding
import NewFund from '@/pages/crowdfund/NewFund';
import FundList from '@/pages/crowdfund/FundList';

// import ChanSearch from '@/pages/ChanSearch';
import ChanSearchDefault from '@/pages/ChanSearchDefault';
import ChanSearchGQLComp from '@/pages/ChanSearchGQLComp';


import LoginForm from '@/components/auth/LoginForm';
import RegisterForm from '@/components/auth/RegisterForm';
// import PlayerOne from '@/components/video/PlayerOne';
import EditorNewPost from '@/components/blog/EditorNewPost';
// import PostComments from '@/components/blog/PostComments';
import EditorUpdatePost from '@/components/blog/EditorUpdatePost';

// import PostCard from '@/components/blog/PostCard';
// import PostCard from '@/components/blog/PostCardV1';

import ExpansionPanel from '@/components/ExpansionPanel';
// const TestComp = { template: `<div style='color: black'>{{ $route }}</div>` }

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/blog/list',
    component: BaseView,
    meta: { title: 'Home' },
    children: [
        {
          path: 'login',
          name: 'Login',
          component: LoginForm,
          meta: { 
            title: 'Login',
            metaTags: [
              {
                name: 'description',
                content: 'Login page for drudgelord.com'
              },
              {
                property: 'og:description',
                content: 'Login page for drudgelord.com'
              }
            ]
          },
        },
        {
          path: 'register',
          name: 'Register',
          component: RegisterForm,
          meta: { 
            protectedRoute: true,
            title: 'Register',
            metaTags: [
              {
                name: 'description',
                content: 'Register page for drudgelord.com'
              },
              {
                property: 'og:description',
                content: 'Register page for drudgelord.com'
              }
            ]
          },
        },
        {
          path: 'flights',
          name: 'Flights',
          component: FlightBookPage,
          meta: { 
            title: 'Flights'
          },
        },
        {
          path: 'face-detection',
          name: 'FaceDetection',
          component: WebCam,
          meta: { 
            title: 'FaceDetection'
          },
        },
    ]
  },
  {
    path: '/testing',
    name: 'testing',
    component: SingleColView,
    children: [
      {
        path: 'post/:postId',
        name: 'testPost',
        component: PostPage,
        meta: { title: 'Post' }
      },
      {
        path: 'list',
        name: 'testPostList',
        component: PostListPage,
        meta: { title: 'Test Post List' }
      },
    ]
  },
  {
    path: '/blog',
    name: 'blog',
    component: BaseView,
    children: [
        {
          path: 'editor',
          name: 'editor',
          component: EditorNewPost,
          meta: { 
            protectedRoute: true,
            title: 'Create Post',
            metaTags: [
              {
                name: 'description',
                content: 'Create blog post page for drudgelord.com'
              },
              {
                property: 'og:description',
                content: 'Create blog post page for drudgelord.com'
              }
            ]
          },
        },
        {
          path: 'post/update/:postId',
          name: 'editPost',
          component: EditorUpdatePost,
          props: true,
          meta: { 
            title: 'Update Post',
            metaTags: [
              {
                name: 'description',
                content: 'Update blog post page for drudgelord.com'
              },
              {
                property: 'og:description',
                content: 'Update blog post page for drudgelord.com'
              }
            ] 
          }
        },
        {
          path: 'list',
          name: 'allPosts',
          component: PostListPage,
          meta: { 
            title: 'Current Blog Posts',
            metaTags: [
              {
                name: 'description',
                content: 'List of all blog posts for drudgelord.com'
              },
              {
                property: 'og:description',
                content: 'List of all blog posts for drudgelord.com'
              }
            ]  
          }
        },
        {
          path: 'post/:postId',
          name: 'post',
          component: PostPage,
          meta: { 
            title: 'Post',
            metaTags: [
              {
                name: 'description',
                content: 'python post'
              },
              {
                property: 'og:description',
                content: 'A blog post page.'
              }
            ] 
          }
        },
      ]
    },
    {
      path: '/crypto',
      name: 'crypto',
      component: BaseView,
      // meta: { protectedRoute: true },
      children: [
          {
            path: 'farms',//no / in path for children
            name: 'farms',
            component: CryptoPage,
            meta: { title: 'Crypto' }
          },
        ]
      },
      {
        path: '/news',
        name: 'news',
        component: BaseView,
        children: [
          {
            path: 'news',
            name: 'current-news',
            component: ExpansionPanel,
            meta: { 
              title: 'News'
            },
          }
        ]
      },
      {
        path: '/funding',
        name: 'funding',
        component: BaseView,
        children: [
            {
              path: 'fund-create',
              name: 'CreateFund',
              component: NewFund,
              meta: { title: 'Create Crowd Fund' }
            },
            {
              path: 'fund-list',
              name: 'ListFunds',
              component: FundList,
              meta: { title: 'Current Funds' }
            },
          ]
      },
      {
        path: '/chan',
        name: 'chan',
        component: BaseView,
        children: [
            {
              path: 'search',
              name: 'chan-search-1',
            //   component: ChanSearch,
              component: ChanSearchGQLComp,
              meta: { title: 'Search 4chan' }
            },
            {
                path: 'search/default',
                name: 'chan-search-2',
                component: ChanSearchDefault,
                meta: { title: 'Default Search' }
              }
          ]
      }
];

export default routes;


      // {
        // path: 'dashboard',
        // name: 'Dashboard',
        // component: Dashboard,
        // meta: { protectedRoute: true },
        // beforeEnter: (to, from, next) => {
        //   if (from.name == 'Login') {
        //     const newUser = JSON.parse( localStorage.getItem("isAuthenticated") );
        //     if ( newUser ) {
        //       next( { path: '/login' } );
        //     } else {
        //       next();
        //     }
        //   } else {
        //     next();
        //   }
        // },
      // },
      // {
      //   path: '/maps',
      //   name: 'MapView',
      //   component: BaseView,
      //   children: [
      //       {
      //         path: 'one',
      //         name: 'maps',
      //         component: `<div>MAPS </div>`,
      //       },
      //     ]
      // }

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home,
//     // children: [
//     //   {
//     //     path: 'chat',
//     //     name: 'Chat',
//     //     component: ChatV1,
//     //   },
//     // ]
//   },
//   {
//     path: '/auth',
//     name: 'AuthPage',
//     component: AuthPage,
    // children: [
    //   {
    //     path: 'login',
    //     name: 'Login',
    //     component: LoginForm,
    //   },
    //   {
    //     path: 'signup',
    //     name: 'SignUp',
    //     component: SignUpForm
    //   },
    //   {
    //     path: 'reset',
    //     name: 'ChangePassword',
    //     component: ChangePassword,
    //   },
    //   {
    //     path: 'forgot-password',
    //     name: 'ForgotPassword',
    //     component: ForgotPassword,
    //   },
    //   {
    //     path: 'forgot-password-final',
    //     name: 'ForgotPasswordFinal',
    //     props: route => ({ query: route.query.q }),
    //     component: ForgotPasswordFinal,
    //     meta: {protectedRoute: true},
    //   },
    // ]
//   },
  // {
    // path: '/dashboard',
    // name: 'Dashboard',
    // component: DashboardPage,
    // meta: {protectedRoute: true},
    // beforeEnter: (to, from, next) => {
    //   if (from.name == 'Login') {
    //     const newUser = JSON.parse( localStorage.getItem("isFirstLogin") );
    //     // console.log('dashboard before enter: ', newUser);
    //     if ( newUser ) {
    //       next( { path: '/reset' } );
    //     } else {
    //       next();
    //     }
    //   } else {
    //     next();
    //   }
    // },
    
  // },

