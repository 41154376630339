<template>
  <!-- <v-card-title> -->
  <!-- <v-card-subtitle> -->
    <!-- <div class="text-div"> -->
      <div class="text-overline mb-1 text-secondary">{{ post.created_date || '' }}</div>
      <div class="text-h5 mb-1">{{ post.title }}</div>
      <div class="text-div text-primary">{{ post.headline }}</div>
      <!-- <div class="text-caption">{{ post.headline }}</div> -->
    <!-- </div> -->
  <!-- </v-card-title> -->
  <!-- </v-card-subtitle> -->


  <!-- <v-card-header-text> -->
  <!-- <v-card-title> {{  $route.params.title }} </v-card-title> -->
  <!-- <v-card-subtitle> {{  $route.params.postId }} </v-card-subtitle> -->
  <!-- <v-card-title> {{  post.title }} </v-card-title> -->
  <!-- <v-card-text > {{  post.created_date }} </v-card-text> -->
  <!-- </v-card-header-text> -->
</template>
<script>
import { defineComponent, reactive } from "vue";
export default defineComponent({
  props: {
    obj: Object,
  },
  setup(props) {
    const post = reactive(props.obj);
    return {
      post: post,
    };
  },
});
</script>
