// import { h } from "vue";
// import { resolveComponent } from 'vue'
// import store from './store'
// import { computed } from 'vue';
// import { useStore } from "vuex";
// const store = useStore();

// const is_admin = !store.getters['auth/isAuthenticated']
// const is_admin = computed(() => {
//   const authed = store.getters['auth/isAuthenticated']
//   console.log('authed', authed)
//   return authed.value
// });


export const authLinks = [
    // {
    //   title: 'Register',
    //   value: 'register',
    //   path: '/register',
    //   permission: false,
    // },
    {
      title: 'Create Post',
      value: 'post-create',
      path: '/blog/editor',
      permission: true
    },
];

export const links = [
    // {
    //   title: 'News',
    //   value: 'news',
    //   path: '/news',
    //   permission: false,
    // },
    {
      title: 'Register',
      value: 'register',
      path: '/register',
      permission: false,
    },
    {
      title: 'Current Posts',
      value: 'post-list',
      path: '/blog/list',
      permission: false
    },
    {
        title: 'Search Com',
        value: 'chan-search-1',
        path: '/chan/search',
        permission: false
      },
      {
        title: 'Search Default',
        value: 'chan-search-2',
        path: '/chan/search/default',
        permission: false
      },
    // {
    //   title: 'Crypto',
    //   value: 'crypto',
    //   path: '/crypto/farms',
    //   permission: false
    // },
    // {
    //   title: 'Create Fund',
    //   value: 'crypto',
    //   path: '/funding/fund-create',
    //   permission: false
    // },
    // {
    //   title: 'Current Funds',
    //   value: 'crypto',
    //   path: '/funding/fund-list',
    //   permission: false
    // },
  ];


// export const navLinks = () => {
  // if(is_admin.value) {
  //   return links.splice(0, 0, authLinks)
  // } else {
  //   return links
  // }
// }

// export const navLinks = () => {
//   if(is_admin) {
//     return links.concat( authLinks)
//   } else {
//     return links
//   }
// }
// function renderIcon(icon) {
//     return () => h(NIcon, null, { default: () => h(icon) });
// }



// export const menuOptions = [
//     {
//         label: "Profile",
//         key: "auth",
//         icon: renderIcon(PersonIcon),
//         children: [
//             {
//                 type: "group",
//                 label: "Step 1",
//                 key: "auth-logged-out",
//                 children: [
//                     {
//                         label: () =>
//                             h(
//                                 resolveComponent('router-link'),
//                                 { to: '/register' },
//                                 { default: () => 'Register' }
//                             ),
//                         key: "register",
//                     },
//                     {
//                         label: () =>
//                             h(
//                                 resolveComponent('router-link'),
//                                 { to: '/login' },
//                                 { default: () => "Login" }
//                             ),
//                         key: "login",
//                     },
//                     {
//                         label: () =>
//                             h(
//                                 NButton,
//                                 { onClick: () => store.dispatch("auth/LOGOUT") },
//                                 { default: () => 'Logout' }
//                             ),
//                         key: 'logout-jwt',
//                     },
//                 ],
//             },
//         ],
//     },
//     {
//         label: () =>
//         h(
//             resolveComponent('router-link'),
//             { to: '/dashboard' },
//             { default: () => 'Dashboard' }
//         ),
//         key: "dashboard",
//         icon: renderIcon(AppsIcon),
//     },
//     {
//         label: () =>
//         h(
//             resolveComponent('router-link'),
//             { to: '/video/cops' },
//             { default: () => 'Videos' }
//         ),
//         key: "cops",
//         icon: renderIcon(VideoIcon),
//     },
//     {
//     label: "Crypto",
//     key: "crypto",
//     icon: renderIcon(BTCIcon),
//     children: [
//         {
//             type: "group",
//             label: "Farms",
//             key: "farms",
//             children: [
//                 {
//                     label: () =>
//                         h(
//                             resolveComponent('router-link'),
//                             { to: '/crypto/farms' },
//                             { default: () => 'Farms' }
//                         ),
//                     key: "farms-page",
//                 },
//             ]
//             }
//         ]
//     },
//     {
//         label: "Maps",
//         key: "maps-group",
//         icon: renderIcon(Globe),
//         children: [
//             {
//                 type: "group",
//                 label: "Maps",
//                 key: "maps-group-one",
//                 children: [
//                     {
//                         key: "map-one",
//                         label: () =>
//                             h(
//                                 resolveComponent('router-link'),
//                                 { to: '/maps/one' },
//                                 { default: () => 'Map 1' }
//                             ),
//                     },
//                 ]
//                 }
//             ]
//         }
// ];


// // const nameColumn = {
// //   title: 'Daily',
// //   key: 'daily_cad',
// //   sortOrder: false,
// //   sorter: 'default'
// // }

// // const ageColumn = {
// //   title: 'Monthly',
// //   key: 'monthly_cad',
// //   sortOrder: false,
// //   sorter (rowA, rowB) {
// //     return rowA.monthly_cad - rowB.monthly_cad
// //   }
// // }


// // const tableColumns = () => {
// //     return [
// //       {
// //         title: "Name",
// //         key: "name",
// //       },
// //       {
// //         title: "Daily Return ($10k)",
// //         key: "daily_10k",
// //         sorter: (row1, row2) => row1.daily_10k - row2.daily_10k,
// //       },
// //       {
// //         title: "Monthly Return ($10k)",
// //         key: "monthly_10k",
// //         sorter: (row1, row2) => row1.monthly_10k - row2.monthly_10k,
// //       },
// //       {
// //         title: "APY",
// //         key: "yield.apy",
// //         render(row) {
// //           return h(
// //             NButton,
// //             { strong: true, tertiary: true, size: "small" },
// //             { default: () => `${row.yield.apy.toFixed(2)} %` }
// //           );
// //         },
// //       },
// //       {
// //         title: "APY Daily",
// //         key: "yield.daily",
// //         render(row) {
// //           return h(
// //             NButton,
// //             { strong: true, tertiary: true, size: "small" },
// //             { default: () => `${row.yield.daily.toFixed(2)}` }
// //           );
// //         },
// //       },
// //       {
// //         title: "Earns",
// //         key: "earns",
// //       },
// //       {
// //         title: "Link",
// //         key: "link",
// //         render(row) {
// //           return h(
// //             "a",
// //             { href: row.link, target: "_blank", rel: "noreferrer noopener" },
// //             { default: () => `${row.link}` }
// //           );
// //         },
// //       },
// //     ];
// //   };

  
// const unionTableColumns = [
//   {
//     title: "Image",
//     key: "image",
//     render(row) {
//       return h(
//         'img',
//         { src: `${row.image}`, width: "20", height: "20" },
//         { default: () => `${row.image}` }
//       );
//     },
//   },
//   {
//     title: "Name",
//     key: "name",
//     sorter: (row1, row2) => row1.name - row2.name,
//   },
//   {
//     title: "Daily Return ($10k)",
//     key: "daily_10k",
//     sorter: (row1, row2) => row1.daily_10k - row2.daily_10k,
//     render(row) {
//       return h(
//         'p',
//         { strong: true, tertiary: true, size: "small" },
//         { default: () => `$ ${row.daily_10k}` }
//       );
//     },
//   },
//   {
//     title: "Monthly Return ($10k)",
//     key: "monthly_10k",
//     sorter: (row1, row2) => row1.monthly_10k - row2.monthly_10k,
//     render(row) {
//       return h(
//         'p',
//         { strong: true, tertiary: true, size: "small" },
//         { default: () => `$ ${row.monthly_10k}` }
//       );
//     },
//   },
//   {
//     title: "APY",
//     key: "yield.apy",
//     render(row) {
//       return h(
//         'p',
//         { strong: true, tertiary: true, size: "small" },
//         { default: () => `${row.yield.apy.toFixed(2)} %` }
//       );
//     },
//   },
//   {
//     title: "APY Daily",
//     key: "yield.daily",
//     render(row) {
//       return h(
//         'p',
//         { strong: true, tertiary: true, size: "small" },
//         { default: () => `${row.yield.daily.toFixed(2)}` }
//       );
//     },
//   },
//   {
//     title: "Earns",
//     key: "earns",
//     sorter: (row1, row2) => row1.earns_new - row2.earns_new,
//   },
//   {
//     title: "Coin Price $",
//     key: "current_price",
//     sorter: (row1, row2) => row1.current_price - row2.current_price,
//   },
//   {
//     title: "Volume",
//     key: "total_volume",
//     sorter: (row1, row2) => row1.total_volume - row2.total_volume,
//   },
//   {
//     title: "Rank",
//     key: "market_cap_rank",
//     sorter: (row1, row2) => row1.market_cap_rank - row2.market_cap_rank,
//   },
//   {
//     title: "Link",
//     key: "link",
//     render(row) {
//       return h(
//         "a",
//         { href: row.link, target: "_blank", rel: "noreferrer noopener" },
//         { default: () => `${row.link}` }
//       );
//     },
//   },
// ];


