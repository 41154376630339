import { createApp, h, provide, render } from 'vue'
// import { provide } from '@vue/composition-api'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
// import { createApolloProvider } from '@vue/apollo-option'
import { DefaultApolloClient } from '@vue/apollo-composable'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from './store'
import axios from 'axios'
import { marked } from "marked";
// import { backendRoutes } from './constants'
import CardTitle from "@/components/CardTitle"
import CardMain from "@/components/CardMain"
import CardHeaderBlog from "@/components/CardHeaderBlog"
import ColSingle from "@/components/ColSingle"

// import { computed } from 'vue';
// import 'highlight.js/styles/stackoverflow-light.css'
// import 'highlight.js/lib/common';
// import hljsVuePlugin from "@highlightjs/vue-plugin";
// import './sass/main.scss'
// import './ress.css'
// import './styles.css'
// import 'animate.css';
// require('animate.css')
loadFonts()


// HTTP connection to the API
const httpLink = createHttpLink( { uri: process.env.VUE_APP_GQL_URL } )
// // Cache implementation
const cache = new InMemoryCache()
// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})
// const apolloProvider = createApolloProvider({
//   defaultClient: apolloClient,
// })




axios.defaults.baseURL = process.env.VUE_APP_URL;
// axios.interceptors.request.use(req => {
//     console.log(`${req.method} ${req.url}`);
//     // Important: request interceptors **must** return the request.
//     return req;
//   });
//   axios.interceptors.response.use(res => {
//     console.log(res.data.json);
//     // Important: response interceptors **must** return the response.
//     return res;
//   });
// axios.interceptors.response.use(
//     res => res,
//     err => {
//       if (err.response.status === 404) {
//         throw new Error(`${err.config.url} not found`);
//       }
//       throw err;
//     }
//   );
const axiosAuth = axios.create({
    // baseURL: process.env.VUE_APP_URL,
    headers: { 'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}` }
})
axiosAuth.interceptors.response.use(function (response) {
        return response
    }, function (error) {
        console.log('error: ', error)
    if (error.response.status == 401) {
        console.log('token expired: refresh token')
        // store.dispatch('auth/refreshAuth')
    }
    return Promise.reject(error)
});
export default axiosAuth;



// export axiosHeaders
// Handle Token Refresh on 401
// function createAxiosResponseInterceptor() {
//     const interceptor = axios.interceptors.response.use(
//       response => response,
//       error => {
//         if (error.response.status !== 401) {
//           return Promise.reject(error);
//         }
//         axios.interceptors.response.eject(interceptor);

//         // return store
//         //   .dispatch("auth/AUTH_REFRESH")
//         //   .then(() => {
//         //     error.response.config.xsrfCookieName = "csrf_access_token";
//         //     error.response.config.xsrfHeaderName = "X-CSRF-TOKEN";
//         //     return axios(error.response.config);
//         //   })
//         //   .catch(error => {
//         //     if (error.response.status !== 401) {
//         //       return Promise.reject(error);
//         //     } else {
//         //       store.dispatch("auth/AUTH_LOGOUT");
//         //       this.router.push("/");
//         //       return Promise.reject(error);
//         //     }
//         //   })
//         //   .finally(() => {
//         //     createAxiosResponseInterceptor();
//         //   });

//       }
//     );
//   }


marked.setOptions({
    renderer: new marked.Renderer(),
    highlight: function(code, lang) {
        const hljs = require('highlight.js');
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, {
            language
        }).value;
    },
    langPrefix: 'hljs language-',//highlight.js css expects a top-level 'hljs' class.
    pedantic: false,
    gfm: true,
    breaks: false,
    sanitize: false,
    smartLists: true,
    smartypants: true,
    xhtml: false
});

const app = createApp({
    setup () {
        provide(DefaultApolloClient, apolloClient)
    },
    render: () => h(App)
})
// const app = createApp(App)
app.component('CardTitle', CardTitle);
app.component('CardMain', CardMain);
app.component('CardHeaderBlog', CardHeaderBlog);
app.component('ColSingle', ColSingle);

// https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin
// app.config.globalProperties.$NAV_LINKS = navLinks;
// app.config.globalProperties.$BACK_ROUTES = backendRoutes;
app.config.globalProperties.$MD = (markdown) => {
    // https://highlightjs.org/static/demo/
    // https://marked.js.org/using_advanced#highlight
    // marked.setOptions({
    //     renderer: new marked.Renderer(),
    //     highlight: function(code, lang) {
    //         const hljs = require('highlight.js');
    //         const language = hljs.getLanguage(lang) ? lang : 'plaintext';
    //         return hljs.highlight(code, {
    //             language
    //         }).value;
    //     },
    //     langPrefix: 'hljs language-',//highlight.js css expects a top-level 'hljs' class.
    //     pedantic: false,
    //     gfm: true,
    //     breaks: false,
    //     sanitize: false,
    //     smartLists: true,
    //     smartypants: true,
    //     xhtml: false
    // });
    return marked.parse(markdown);
}
// app.config.globalProperties.$apollo = apolloClient
app.use(vuetify)
app.use(router)
app.use(store)
// this is for basic options setup
// app.use(apolloProvider)
// app.use(hljsVuePlugin)
app.mount('#app')