<template>
  <v-app>
    <!-- <v-layout style="overflow: hidden"> -->
      <v-layout >
      <v-app-bar app dense absolute >
        <v-app-bar-nav-icon aria-label="Expand Navigation Drawer Icon" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <!-- <v-img :src="require('@/assets/logo.png')" class="my-2" contain width="40" height="30" /> -->
        <!-- <v-toolbar-title>{{ time }}</v-toolbar-title> -->
        <v-spacer></v-spacer>

        <v-btn v-if="$store.getters['auth/isAuthenticated']" color='primary' rounded xs-small class='mr-3 pa-2' @click='LOGOUT'>Logout</v-btn>
        <v-btn v-else rounded xs-small color='primary' class='mr-3 pa-2' :to='{ name: `Login` }' >Login</v-btn>

      </v-app-bar>


      <v-navigation-drawer app absolute temporary v-model="drawer" >
        <v-list nav dense>
          <div v-for="(item, i) in items" :key="i">
            <v-list-item link :to="item.path">
              {{ item.title }}
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>


      <v-main>
        <v-container fluid>
          <slot name="content-slot"></slot>
          <snack-bar />
        </v-container>
      <!-- <footer-main >Footer</footer-main> -->
      </v-main>
	</v-layout>
  </v-app>
</template>
<script>
// import NavTop from '@/NavTop'
import { mapActions, mapGetters }  from 'vuex'
import { authLinks, links } from '@/NavLinks'
// import FooterMain from '@/components/FooterMain'
import SnackBar from '@/components/SnackBar'

export default {
  components: {
    SnackBar
  },

  data: () => ({
    drawer: false,
    group: null,
  }),

  methods: {
    ...mapActions({LOGOUT: 'auth/logout'}),
  },

  computed: {
    ...mapGetters({isAuth: 'auth/isAuthenticated'}),

    items() {
      if(this.isAuth) {
        return links.concat(authLinks)
      } else {
        return links
      }
      // return this.$NAV_LINKS;
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },


//       getTime() {
//       let connection = new WebSocket('ws://localhost:5000/time');
//       connection.onmessage = (event) => {
//         this.time = event.data;
//     }
//   },
};
</script>
