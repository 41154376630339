<template>
<v-container >
<!-- <v-row> -->
  <!-- <col-single > -->
      <v-card rounded plain class="pa-md-1 mx-auto" max-width='465px'>
      <!-- <v-card class='auth-card'> -->
      <v-form @submit="onSubmit" class='form-auth'>
        <v-container>
          <v-row justify='center'>
            <v-col class="mx-auto" cols="12" md="8">
              <card-title title='Register'></card-title>
                <!-- <v-card-text class='text-center' >Register</v-card-text> -->
                <!-- <v-card-title class='mx-auto '>Register</v-card-title> -->
              <v-text-field
                placeholder="capcom"
                name="username"
                label='Your User Name'
                v-model="username"
                variant="plain"
                density='comfortable'
                clearable
                :error-messages="errors.username"
              />
              <v-text-field
                placeholder="capcombravo@protonmail.com"
                name="email"
                label='E-Mail'
                v-model="email"
                variant="plain"
                density="comfortable"
                clearable
                :error-messages='errors.email'
              />
              <v-text-field
                placeholder="qwerty"
                name="password"
                label='Password'
                v-model="password"
                type="password"
                variant="plain"
                density='comfortable'
                hint="Minimum of 8 characters"
                counter="25"
                clearable
                :error-messages="errors.password"
              />
                <v-divider class="mb-5"></v-divider>

              <v-card-actions>
                <v-btn rounded xs-small variant='outlined' color='primary' aria-label="Register Button" type="submit">Register</v-btn>
                <!-- <button :disabled="!meta.dirty">Submit</button> -->
              </v-card-actions>

            </v-col>
          </v-row>
        </v-container>
      </v-form>

    </v-card>

      <!-- </col-single> -->
<!-- </v-row> -->

</v-container>
</template>
<script>
import { useStore } from "vuex";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
// https://vee-validate.logaretm.com/v4/guide/composition-api/validation
export default {
  setup() {
    const store = useStore();

    const schema = yup.object({
      username: yup.string().required().min(2),
      email: yup.string().required().email(),
      password: yup.string().required().min(2),
    });

    const { errors, handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: username } = useField("username");
    const { value: email } = useField("email");
    const { value: password } = useField("password");

    const onSubmit = handleSubmit((values) => {
      console.log(values);
      store.dispatch("auth/register", values);
    });

    return {
      username,
      email,
      password,
      errors,
      onSubmit,
    };
  },
};
</script>

