<template>
  <v-container>
    <v-row class="d-flex align-center flex-sm-row flex-column">

      <v-col>
        <v-card >

          <card-title title='APR Calculator'></card-title>
          <!-- <v-card-text class='form-signin'> -->
          <v-card-text >
            <!-- <v-row> -->
            <v-row class="d-flex align-center flex-sm-row flex-column ">

              <v-col>
                <v-text-field
                  step='1'
                  type='number'
                  name="APY"
                  label="APY"
                  v-model="apy"
                  variant='outlined'
                  class='input-text-color'
                />
              </v-col>
              <v-col>
                <v-text-field
                step='1'
                variant='outlined'
                  type="number"
                  name="inv"
                  label="Investment"
                  v-model="investment"
                  class='input-text-color'
                />
              </v-col>
            </v-row>


          </v-card-text>

        </v-card>
      </v-col>

      <v-col>
        <v-card >

          <card-title title='APR Calculator'></card-title>

          <v-card-text >
            <v-chip label color='secondary' text-color='white' class='ma-2 pa-3'>
                <v-icon left icon="mdi-currency-usd"></v-icon>
                Daily Return: {{ calculate.toFixed(2) }}
            </v-chip>

        <v-chip label color='secondary' text-color='white' class='ma-2 pa-3' >
                <v-icon left icon="mdi-percent"></v-icon>
              Daily Yield: {{ daily_yield.toFixed(2) }}
        </v-chip>

        <br/>
        <v-chip label color='primary' text-color='white' class='ma-2 pa-3'>
          {{ apy_daily_formula }}
        </v-chip>

        <v-chip label color='primary' text-color='white' class='ma-2 pa-3'>
          {{ daily_return_formula }}
        </v-chip>

          </v-card-text>
        </v-card>
      </v-col>

      <!-- </div> -->
    </v-row>

    <v-row>
      <v-col>
        <FarmGrid />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <LpGrid />
      </v-col>
    </v-row>

  </v-container>
</template>
<script >
import { computed, ref } from "vue";
import FarmGrid from "@/components/crypto/FarmGrid";
import LpGrid from "@/components/crypto/LpGrid";

export default {
  components: { 
    FarmGrid,
    LpGrid,
  },
  setup() {
    const apy_daily_formula = 'APY / 365'
    const daily_return_formula = "Daily Return $ = (APY Daily / 100) * Total Investment"

    const apy = ref();
    const investment = ref();

    const daily_yield = computed(() => {
      const yy = apy.value / 365;
      // return parseFloat(yy).toFixed(2);
      return yy;
    });

    const calculate = computed(() => {
      const daily_return = (daily_yield.value / 100) * investment.value;
      // const daily_return = parseFloat((apy.value / 100)) * parseFloat(investment.value);
    //   console.log("daily_return: ", daily_return);
      return daily_return;
    });
    return {
      apy,
      investment,
      calculate,
      daily_yield,
      apy_daily_formula,
      daily_return_formula,
    };
  },
};
</script>
