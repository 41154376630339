import axios from "axios";
import axiosAuth from '../../main'
// import { backendRoutes } from "../../constants";

const state = {
    postArr: [],
};
// getters get data from the state
// Getters are computed properties
// syncronous
const getters = {
    getPosts: state => state.postArr,
    getPostById: (state) => (id) => {
        return state.postArr.find(post => post._id === id)
    }
};
//mutations update the state
//convention to use uppercase letters for mutations
const mutations = {
    SET_POSTS(state, data) {
        state.postArr = data;
    }
};

const actions = {

    async readSinglePost( {state}, pid ) {
        // console.log(state)
        const post = await axios.get(`/blog/post/${pid}` )
        // console.log(post.data)
        return post.data
    },
    async createPost({ dispatch }, data) {
        try {
            const r = await axiosAuth.post(`/post`, data)
            // const r = await axios.post(`/post`, data)
            // refresh all posts into state, to include new post
            await dispatch('listPosts')
            console.log('created posts successfully')
            return r.data
        } catch (e) {
            console.warn(e)
        }
    },
    async listPosts( { commit } ) {
        const posts = await axios.get(`/post/all`)
        commit('SET_POSTS', posts.data)
        return posts.data
    },

    async updatePost( { dispatch }, data) {
        try{
            const { url, body } = data;
            const r = await axios.put( url, body );
            await dispatch('listPosts')
            return r.data;
        } catch(e) {
            console.warn('GetById Error: ', e)
        }
    },

    async deletePost( { dispatch }, data ) {
        try{
            const { url } = data;
            const r = await axios.delete( url );
            await dispatch('listPosts')
            // console.log(r.data)
            dispatch(
                'RootSnackBar', 
                {btnColor: 'green', 
                cardColor: 'black', 
                statusText: `Success`, 
                data: {info: `${r.data}` }}, 
                { root: true }
            );
        } catch(e) {
            console.warn('delete Post: ', e)
        }
    },

    async createPostV2( { dispatch }, data) {
        try{
            const { url, body } = data;
            const r = await axios.post( url, body );
            await dispatch('listPosts')
            return r.data;
        } catch(e) {
            console.warn('GetById Error: ', e)
        }
    },


    // createPost( { state } ) {

    //   const newBlogPost = {
    //     title: state.title, 
    //     headline: state.headline, 
    //     content: state.content}
    //   // console.log('newPost: ', newBlogPost)
    //   // axios.post('/post_blog_form', newBlogPost)
    //   // .then(resp => {
    //   //   console.log('post json: ', resp)
    //   // })

    //   Array.from(state.img).map(image => {

    //       const postImage = new FormData();
    //       postImage.append('headline_image', image)

    //       axios.post('/post_blog_form', newBlogPost )
    //       .then(resp => {
    //         console.log('json: ', resp)
    //         // axios.post('/post_blog_form', postImage).then(resp => { console.log('form: ', resp) })
    //       })
    //   });

    // },

    // createPost( { state }, data ) {
    //     console.log(data);
    // console.log('create post state action')
    // Array.from(state.img).map(image => {
    // const newForm = new FormData();  
    // newForm.append('title', state.title)
    // newForm.append('headline', state.headline)
    // newForm.append('headline_image', state.img)
    // newForm.append('headline_image', image)
    // newForm.append('imageList', image)
    // newForm.append('content', state.content)
    // console.log('newForm: ', newForm)
    // return axios.post('/post_blog_form', newForm);
    // });
    // },

};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
};