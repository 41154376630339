import { createStore } from 'vuex'
// import { createLogger } from 'vuex'
import authJWT from './modules/authJWT'
// import imgHandler from './modules/imgHandler'
import crowdfund from './modules/crowdfund'
import analytics from './modules/analytics'
import blog from './modules/blog'
import flights from './modules/flights'
import axios from 'axios'

// const debug = process.env.NODE_ENV !== 'production'
export default createStore({
    state: {
        status: "",
        content: "",
        bottom: false,
        color: "",
        visible: false,
        btnColor: "",
    },
    mutations: {
        SET_SNACK(state, snackConfig) {
            state.content = snackConfig.content;
            state.bottom = true;
            state.color = snackConfig.cardColor;
            state.btnColor = snackConfig.btnColor;
            state.visible = true;
        },
        CLEAR_SNACK(state) {
            state.content = "";
            (state.bottom = false), (state.btnColor = "");
            (state.color = ""), (state.visible = false);
        },

    },
    actions: {
        RootSnackBar({ commit }, info) {
            commit('SET_SNACK', {
                    cardColor: info.cardColor,
                    btnColor: info.btnColor,
                    content: info.statusText + ": " + info.data.info
                },
            );
            // close snackbar
            setTimeout(() => { commit('CLEAR_SNACK') }, 5000)
        },

        async getById( { context }, url) {
            try{
                const r = await axios.get(url)
                return r.data
            } catch(e) {
                console.warn('GetById Error: ', e)
            }
        },
        async deleteDocument( { dispatch }, data) {
            try {
                const { url } = data
                console.log(url)
                // const r = await axios.delete( url );
                // await dispatch('listPosts')
                // return r.data;
            } catch(e) {
                console.warn(e)
            }
        },

    },

    modules: {
        auth: authJWT,
        crowdfund: crowdfund,
        analytics: analytics,
        blog: blog,
        flights: flights,
        // imageStore: imgHandler,
    },
    // strict: debug,
    // plugins: debug ? [createLogger()] : []
});


