import axios from "axios";
import router from "@/router/index";
// import qs from 'qs'
// import { SET_STATUS, SET_SNACKBAR, CLEAR_SNACKBAR, GET_USERNAME } from '../mutation-types'
import { LOGIN_JWT } from '../action-types'
// import axiosHeaders from '../../main'
import { SET_STATUS, GET_USERNAME } from '../mutation-types'

const state = () => ({
	userId: window.localStorage.getItem("userId") || "",
	username: window.localStorage.getItem("username") || "",
	accessToken: window.localStorage.getItem("accessToken") || null,
	status: "",
	authDisabled: null,
});
const getters = {
	getUserId: state => state.userId,
	isAuthenticated: state => !!state.username,
	[GET_USERNAME]: state => state.username,
};
const mutations = {
	[SET_STATUS](state, status) {
		state.status = status;
	},
	AUTH_CLEAR(state) {
		window.localStorage.removeItem("username");
		window.localStorage.removeItem("accessToken");
		window.localStorage.removeItem("userId");
		state.accessToken = null;
		//   state.refreshToken = "";
		state.username = "";
		state.status = "";
		state.userId = "";
	},
	SET_JWT(state, resp) {
		console.log('SET_JWT', resp);
		state.accessToken = resp.data.access_token;
		window.localStorage.setItem("accessToken", resp.data.access_token);
	},
	AUTH_SUCCESS(state, resp) {
		console.log('AUTH_SUCCESS: ', resp)
		state.username = resp.data.email;
		window.localStorage.setItem("username", resp.data.email);

		state.userId = resp.data._id;
		window.localStorage.setItem("userId", resp.data._id);
	},

    AUTH_REQUEST(state) {  state.status = "loading"; },
    AUTH_ERROR(state) {  state.status = "error"; },
};

const actions = {

	logout({ commit }) {
		// console.log('logout before: ', state);
		commit('AUTH_CLEAR');
		// console.log('logout after: ', state);
		router.push( { name: "Login" } );
	},

	register({ commit, dispatch }, creds) {
		return new Promise((resolve, reject) => {
			axios
				.post('/api/auth/register',
					{ username: creds.username, email: creds.email, password: creds.password })
				.then(resp => {
					console.log('register: ', resp)
					// commit('SET_JWT');
					resolve(resp);
					dispatch(
						'RootSnackBar', 
						{btnColor: 'green', 
							cardColor: 'dark', 
							statusText: `Success`, 
							data: {info: `Register` }},
						{ root: true }
					);

				}).catch(err => {
					console.log('register err: ', err.response)
					reject(err);
					dispatch(
						'RootSnackBar', 
						{btnColor: 'green', 
							cardColor: 'dark', 
							statusText: `Error`, 
							data: {info: `Register` }},
						{ root: true }
					);
				})
		})
	},

	[LOGIN_JWT]: ({ commit, dispatch }, creds) => {
		commit('AUTH_REQUEST');
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			// formData.set('username', creds.username);
			formData.set('username', creds.email);
			formData.set('password', creds.password);

			const url = "/api/auth/login";
			axios
				.post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
				.then(resp => {
					commit('SET_JWT', resp);
					// localStorage.setItem("username", resp.data.username);
					resolve(resp);
					axios
						.get('/api/auth/users/me', { headers: { 'Authorization': `Bearer ${resp.data.access_token}` } })
						.then(resp => {
							dispatch(
								'RootSnackBar', 
								{btnColor: 'green', 
									cardColor: 'black', 
									statusText: `Success`, 
									data: {info: `Logged In` }},
								{ root: true }
							);

							commit('AUTH_SUCCESS', resp);
							resolve(resp);
							router.push( { name: "allPosts" } );

						}).catch(err => {
							commit('AUTH_CLEAR')
							console.log('AUTH_CLEAR users/me: ', err);
							reject(err);
							dispatch(
								'RootSnackBar', 
								{btnColor: 'green', 
									cardColor: 'dark', 
									statusText: `Failed`, 
									data: {info: `AUTH_CLEAR` }},
								{ root: true }
							);
						})

				}).catch(err => {
					commit('AUTH_ERROR', err);

					if (err.response.status != 200) {
						console.warn('Login Error: ', err.response.data.detail.color);
						console.warn('Login Error: ', err.response.data.detail.message);
						dispatch('RootSnackBar', {
							btnColor: 'red', 
							cardColor: 'dark', 
							statusText: `Error`, 
							data: {info: `${err.response.data.detail.message}` }},
							{ root: true }
						);
					} else {
						console.warn('Login Other Error: ', err.response.data.detail);
					}
					commit('AUTH_CLEAR')
					// localStorage.removeItem("username");
					reject(err);
				});
		});
	},

	// [LOGIN_COOKIE]: ({ commit }, creds) => {
	//   return new Promise((resolve, reject) => {
	//     // commit(AUTH_REQUEST);
	//     const url = "/api/auth/login";

	//     const formData = new FormData();
	//     formData.set('username', creds.username);
	//     formData.set('password', creds.password);

	//     axios
	//     .post(url, {username: creds.username, password: creds.password}, { withCredentials: true })
	//     .then(resp => {
	//         console.log('login: ', resp);
	//         // localStorage.setItem("username", resp.data.username);
	//         // axios.defaults.withCredentials = true;
	//         // axios.defaults.xsrfCookieName = "csrf_access_token";
	//         // axios.defaults.xsrfHeaderName = "X-CSRF-TOKEN";
	//         // commit(AUTH_SUCCESS, resp);
	//         resolve(resp);
	//       })
	//       .catch(err => {
	//         console.log('err ', err);
	//         // commit(AUTH_ERROR, err);
	//         // commit("snackbar/setErr", err, { root: true });
	//         // localStorage.removeItem("username");
	//         reject(err);
	//       });
	//   });
	// },

	// async [LOGIN_ACTION]({ commit, dispatch }, creds) {
	//     // commit('AUTH_REQUEST');
	//     commit(SET_STATUS, 'loading');

	//     const formData = new FormData();
	//     formData.set('username', creds.username);
	//     formData.set('password', creds.password);
	//     await axios
	//     .post(`/auth/jwt/login`, formData, {headers: { 'Content-Type': 'multipart/form-data' }})
	//     .then(response => {

	//       console.log('login response: ', response)
	//       commit('SET_JWT', response)

	// axios
	// .get('/users/me', { headers: {'Authorization': `Bearer ${window.localStorage.getItem('accessToken')}` } })
	// .then(resp => {
	//   console.log('users_me: ', resp);

	//   dispatch("snackBar", { color: 'green', btnColor: 'white', statusText: `Welcome ${resp.data.email}`, data: {info: ''}});
	//   commit('AUTH_SUCCESS', resp);

	//   router.push("/dashboard");

	// }).catch(err => {
	//   console.log('auth err-1: ', err);

	//         // commit('AUTH_ERROR', err);
	//         commit(SET_STATUS, 'error');

	//         let err_msg = '(Login): Please try again.' + err.response.statusText + ": " + err.response.data.detail
	//         // dispatch("snackBar", {cardColor: 'error',  btnColor: 'white',  content: err_msg} );
	//         dispatch("snackBar", { color: 'red', btnColor: 'white', statusText: `Welcome ${resp.data.email}`, data: {info: ''}});

	//         commit('AUTH_CLEAR');
	//         commit('REMOVE_STORAGE');
	//         // router.push("/login");
	//       })
	//     }).catch(err => {
	//       console.log('auth err-2: ', err.response);
	//       dispatch("snackBar", { color: 'red', btnColor: 'white', statusText: `Username/Password Incorrect, Please try again.`, data: {info: ''}});

	//       // commit('AUTH_ERROR', err);
	//       commit(SET_STATUS, 'error')
	//       commit('REMOVE_STORAGE')
	//     })
	//   },










}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};
