import axios from "axios";
// import router from "@/router/index";
// import fastapi from '../../api/fastapi'
// import axiosHeaders from '../../main'
// import { SET_SNACKBAR } from '../mutation-types'
// import { SET_STATUS } from '../mutation-types'

  const state = {
    fundArr: [],
  };
  // - gets data from the state
  // - are computed properties
  // - are syncronous
  const getters = {
    getCrowdFunds: state => state.fundArr,
  };
  // - updates state
  // - convention to use uppercase letters for mutations
    const mutations = {
      SET_FUND_LIST(state, data) {
        // console.log('data: ', data)
        state.fundArr = data;
        // console.log('state: ', state.fundArr)
    },
};

  const actions = {

    async newCrowdFund({ commit }, data) {
    //   console.log('new crowd fund: ', data)
      const resp = await axios.post('/address/new', data);
      console.log(resp.data)
      return resp.data;
    },
    async listFunds( { commit } ) {
      const funds = await axios.get(`/fund/list`)
    //   console.log('listFunds: ', funds.data)
      commit('SET_FUND_LIST', funds.data)
      return funds.data
  },

    // async read_crowd_fund({ state, commit, dispatch }) {
    //     const url = `/read/stripe/product/all`;
    //     let resp = await axiosHeaders.get(url)
    //     console.log('read_products: ', resp)
    //     commit('SET_PRODUCT_STATE', resp.data);
    // },

    // async query_products({ state, commit, dispatch }, event) {
    //   // if(!state.productState.length ) {
    //     console.log('event', event);
    //     const url = `/query_db/${event.query}/${event.filter}`;
    //     let resp = await axiosHeaders.get(url)
    //     // let resp = dispatch('query_db', event)
    //     console.log('query products: ', resp)
    //     commit('SET_PRODUCT_STATE', resp.data);
    //   // } else {
    //     // console.log('state already loaded')
    //   // }
    // },


    // async postForm({ }, obj) {
    //   console.log('postform', obj)
    //   Array.from(obj.product_img_list).map(image => {
    //       const newForm = new FormData();
    //       newForm.append('name', obj.product_name);
    //       newForm.append('description', obj.product_description);
    //       newForm.append('price', obj.price);
    //       newForm.append('shipping_cost', obj.shipping_cost);
    //       newForm.append('img_list', image);
    //       return axios.post('/post_form', newForm);
    //   });
    // },


    // async cartCheckout({ state }) {
    //   const cart_items = { cart: state.cart };
    //   await axios
    //       .post(`/cart-checkout`, { cart_items })
    //       .then(resp => {
    //           // console.log('checkout ', resp)
    //           this.payWithCard(this.stripe, this.card, resp.data.clientSecret);
    //       })
    // }
};



  export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
  };

