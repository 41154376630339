<template>
  <v-card class="mx-auto">
    <!-- <v-form id="search" class="form-signin"> -->
    <v-form id="search" >
      <v-container>
        <v-row>

          <v-col class="mx-auto" cols="12" md="3">

          <card-title title='EVM LP Tokens'></card-title>

            <v-chip 
              class="mb-5 pt-9 my-auto"
              style='width: 100%;'
            >
              <v-text-field  class='input-text-color' variant='plain' density='comfortable' name="query" label="Search" v-model="searchQuery" />
            </v-chip>

            <v-chip 
              class="mb-5 pt-9 my-auto"
              style='width: 100%;'
            >
              <v-select variant='plain' density='comfortable' v-model="select" :items="items" label="Limit"></v-select>
            </v-chip>

            <div
              class="
                d-flex
                justify-space-between
                align-center
                flex-column 
                flex-sm-row
              "
            >
              <v-btn
                @click="pageDown"
                icon="mdi-arrow-left"
                color="primary"
              ></v-btn>

              <v-btn
                @click="pageUp"
                icon="mdi-arrow-right"
                color="primary"
              ></v-btn>

              <v-btn
                @click="updateTable"
                icon="mdi-update"
                color="secondary"
                variant="outlined"
              ></v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>


    <DemoGrid
      :data="gridData"
      :columns="gridColumns"
      :filter-key="searchQuery"
    ></DemoGrid>
  </v-card>

<!-- </v-card> -->
</template>
<script >
import { computed } from "vue";
import { useStore } from "vuex";
import DemoGrid from "./GridTableComponent.vue";
import { ref } from "vue";
// import CardTitle from "@/components/CardTitle"

export default {
  components: { 
    DemoGrid,
    // CardTitle,
  },
  // composition api notes:
  // - replace methods with regular funcs
  setup() {
    const items = [0, 5, 10, 15, 20];
    const select = ref(5);

    const store = useStore();
    const searchQuery = ref("");
    const headers = computed(() => store.getters["analytics/getLpTokenHeaders"]);

    const data = computed(() => {
      // console.log("selected: ", select.value);
      store.commit("analytics/SET_LIMIT", select.value);
      // when state changes; this will execute and update automatically
      return store.getters["analytics/lpTokenGetter"];
    });

    function pageUp() {
    //   console.log("page up", select.value);
      store.commit("analytics/PAGE_UP");
      store.dispatch("analytics/lpTokenAction");
    }
    function pageDown() {
    //   console.log("page down", select.value);
      store.commit("analytics/PAGE_DOWN");
      store.dispatch("analytics/lpTokenAction");
    }
    function updateTable() {
      // console.log("update");
      store.dispatch("analytics/lpTokenAction");
    }

    return {
      updateTable,
      pageUp,
      pageDown,
      select: select,
      items: items,
      searchQuery: searchQuery,
      gridColumns: headers,
      gridData: data,
      // gridData: computed(() => store.getters['analytics/farmGetter'])
    };
  },
};

// - replace methods with regular funcs
// import DemoGrid from "./GridTable.vue";
// import { ref } from "vue";
// const searchQuery = ref("");
// const gridColumns = ["name", "power"];
// const gridData = [
//   { name: "Chuck Norris", power: Infinity },
//   { name: "Bruce Lee", power: 9000 },
//   { name: "Jackie Chan", power: 7000 },
//   { name: "Jet Li", power: 8000 },
// ];
// <!-- <input name="query" v-model="searchQuery" placeholder="Search" style="padding: 10px; margin: 10px; color: white;"/> -->

// const gridColumns = [
// "name", "token", "symbol",
// "current_price", "total_volume",
// "daily_10k", 'monthly_10k', "yield",
// "link"];
// console.log('select: ', select.value);
</script>
