
<template>
<!-- class="pa-md-1 mx-sm-auto mx-auto card-style" -->
<!-- style="max-width: 465px;" -->
<v-card>
<!-- <v-card :width="height" class='mx-auto'> -->
    <slot />
</v-card>
</template>
<script>
import { useDisplay } from 'vuetify'
import { computed } from 'vue'
// https://next.vuetifyjs.com/en/features/display-and-platform/
export default {
  // PostCard.vue
  //
    setup () {
        const { name } = useDisplay()
        
        const height = computed(() => {
        // name is reactive and
        // must use .value
        // switch (name.value) {
        //   case 'xs': return 220
        //   case 'sm': return 400
        //   case 'md': return 500
        //   case 'lg': return 600
        //   case 'xl': return 800
        //   case 'xxl': return 1200 }
        switch (name.value) {// PostCard Dimensions
          case 'xs': return 340
          case 'sm': return 720
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 1100
          case 'xxl': return 1200 
        }
        return name.value
    })

    // console.log('card size: ', height.value)
    return { 
        height: height 
        }

    }
  }
</script>