<template>

<v-container>
    <v-card
      rounded
      class="pa-md-1 mx-sm-auto mx-md-auto card-style"
      max-width="465px"
    >
      <v-form @submit="onSubmit" class="form-auth">
        <v-container>
          <v-row>
            <!-- <v-card > -->

            <v-col class="mx-auto " cols="12" md="8">
          <card-title data-testid='login-title' title='Login'></card-title>

            <!-- <v-card > -->

            <!-- <v-chip
              class="mb-5 pt-9 my-auto"
              style='width: 100%;'
              color="error"
            > -->
              <!-- <v-text-field
                placeholder="Your User Name"
                name="username"
                label='User Name'
                v-model="username"
                variant="plain"
                density='comfortable'
                clearable
                :error-messages="errors.username"
              /> -->
            <!-- </v-chip> -->

              <v-text-field
                placeholder="your@email.com"
                name="email"
                label='E-Mail'
                v-model="email"
                variant="plain"
                density="comfortable"
                clearable
                :error-messages='errors.email'
              />
              <v-text-field
                placeholder="Your Password"
                name="password"
                label='Password'
                v-model="password"
                type="password"
                variant="plain"
                density='comfortable'
                hint="Minimum of 6 characters"
                counter="25"
                clearable
                :error-messages="errors.password"
              />
              
              <v-divider class="mb-5"></v-divider>
              <v-card-actions>
                <v-btn rounded xs-small variant='outlined' color='primary' aria-label="Login Button" type="submit">Login</v-btn>
              </v-card-actions>
            </v-col>

          </v-row>
        </v-container>
      </v-form>
    </v-card>

</v-container>
    <!-- </card-main> -->
</template>
<script>
import { useStore } from "vuex";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
// https://vee-validate.logaretm.com/v4/guide/composition-api/validation
export default {
  components: {
  },
  setup() {
    const store = useStore();
    const schema = yup.object({
      // username: yup.string().required(),
      email: yup.string().required().email(),
      password: yup.string().required().min(6),
    });

    const { handleSubmit, errors } = useForm({
      validationSchema: schema,
    });

    // const { value: username } = useField("username");
    const { value: email } = useField("email");
    const { value: password } = useField("password");

    const onSubmit = handleSubmit((values) => {
      store.dispatch("auth/LOGIN_JWT", values);
    });

    return {
      // username,
      email,
      password,
      errors,
      onSubmit,
    };
  },
};
</script>

