<template>
  <div class="container">
    <h1 class="my-3 text-black">Real time face detection</h1>

    <div class="input-group mb-3">
      <!-- <button color='primary' type="button" id="button-start">
        Start
      </button> -->
      <v-btn color='primary' type="button" id="button-start">
        Start
      </v-btn>
      <br />

      <!-- <div v-if="isCameraOpen" class="camera-shoot"> -->
      <div class="camera-shoot">
        <button type="button" class="button" @click="takePhoto">
          <img
            src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
          />
        </button>
      </div>
    </div>

    <div class="position-relative">
      <!-- <a
        id="downloadPhoto"
        download="my-photo.jpg"
        class="button"
        role="button"
        @click="downloadImage"
      >
        Download
      </a> -->
      <!-- <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas> -->
      <canvas
        id="photoTaken"
        ref="photoCanvas"
        :width="450"
        :height="337.5"
      ></canvas>
    </div>

    <div class="position-relative">
      <video id="video" ref="video"></video>
      <canvas
        id="canvas"
        ref="canvas"
        class="position-absolute top-0 start-0"
      ></canvas>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deviceId: null,
      isVideo: false,
      isPhoto: false,
      // isCameraOpen: false,
      // isPhotoTaken: false
    };
  },
  methods: {
    drawFaceRectangles(video, canvas, faces) {
      const ctx = canvas.getContext("2d");
      ctx.width = video.videoWidth;
      ctx.height = video.videoHeight;
      ctx.beginPath();
      ctx.clearRect(0, 0, ctx.width, ctx.height);
      for (const [x, y, width, height] of faces.faces) {
        ctx.strokeStyle = "#49fb35";
        ctx.beginPath();

        // Faces model drawn onto image
        ctx.rect(x, y, width, height);
        ctx.stroke();
      }
    },

    startFaceDetection(video, canvas, deviceId) {
      const socket = new WebSocket("ws://localhost:5000/page-detection");
      let intervalId;
      // Connection opened
      socket.addEventListener("open", () => {
        // Start reading video from device
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              deviceId,
              width: { max: 640 },
              height: { max: 480 },
            },
          })
          .then(function (stream) {
            video.srcObject = stream;
            video.play().then(() => {
              // Adapt overlay canvas size to the video size
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              // Send an image in the WebSocket every 42 ms
              intervalId = setInterval(() => {
                // Create a virtual canvas to draw current video image
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                // console.log("drawImage video: ", video);
                ctx.drawImage(video, 0, 0);
                // Convert it to JPEG and send it to the WebSocket
                canvas.toBlob((blob) => socket.send(blob), "image/jpeg");
              }, 42);
            });
          });
      });

      socket.addEventListener("message", (event) => {
        // x, y, w, h from backend to draw onto rectangle
        this.drawFaceRectangles(video, canvas, JSON.parse(event.data));
      });
      // Stop the interval and video reading on close
      socket.addEventListener("close", () => {
        window.clearInterval(intervalId);
        video.pause();
      });
      return socket;
    },

    takePhoto() {
      //   this.isPhotoTaken = !this.isPhotoTaken;
      const ctx = this.$refs.photoCanvas.getContext("2d");
      ctx.drawImage(this.$refs.video, 0, 0, 450, 337.5);
    },

    downloadImage() {
      // const h1 = "application/x-www-form-urlencoded";
      const h1 = "multipart/form-data";
      //   const h1 = "image/octet-stream"
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", h1);
      // .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);

      //   let formData = new FormData();
      //   formData.append("file", canvas);
      //   console.log("canvas: ", formData);
      const canv = document.getElementById("photoTaken");
      canv.toBlob((blob) => {
        const file = new File([blob], "image.jpg");
        console.log(file);

        const body = new FormData();
        // body.append("file", "@my-photo (21).jpg;type=image/jpeg")
        body.append("file", file.filename);
        fetch("http://localhost:5000/file", {
          body,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          method: "POST",
        });

        // fetch("http://localhost:5000/file", {
        //   method: "POST",
        //   files: file,
        //   headers: { "Content-Type": h1, 'accept': 'application/json' },
        // });
        // let result = response.json();
        // console.log("result", result);
      });

      //   let response = await fetch("http://localhost:5000/file", {
      //     method: "POST",
      //     body: formData,
      //     headers: { "Content-Type": h1 },
      //   });
      //   let result = await response.json();
      //   console.log("result", result);
    },
  },

  mounted() {
    // const video = document.getElementById("video");
    // const canvas = document.getElementById("canvas");
    const video = this.$refs.video;
    const canvas = this.$refs.canvas;

    let socket;

    // List available cameras and fill select
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      for (const device of devices) {
        console.log("dev id: ", device.deviceId);
        if (device.kind === "videoinput" && device.deviceId) {
          this.deviceId = device.deviceId;
        }
      }
    });

    // Start face detection on the selected camera on submit
    document.getElementById("button-start").addEventListener("click", (event) => {
        event.preventDefault();
        // Close previous socket is there is one
        if (socket) {
          socket.close();
        }
        // const deviceId = cameraSelect.selectedOptions[0].value;
        socket = this.startFaceDetection(video, canvas, this.deviceId);
        console.log("socket: ", socket);
      });
  },
};
</script>
<style scoped>
.img-btn button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.img-img img {
  height: 35px;
  object-fit: cover;
}
</style>
