<template>
  <v-form @submit="onSubmit" class="form-md-editor">
    <v-text-field
      name="title"
      label="What's your post's title?"
      color="primary"
      variant="outlined"
      density="comfortable"
      type="text"
      v-model="title"
      class="pa-2"
    />

    <v-text-field
      name="headline"
      label="A headline for your post to create interest."
      color="primary"
      variant="outlined"
      density="comfortable"
      type="text"
      v-model="headline"
      class="pa-2"
    />

    <div class="editor">
      <textarea class="input" :value="input" @input="update"></textarea>
      <div class="output" v-html="$MD(output)"></div>
    </div>

    <v-btn rounded xs-small color="primary" class="ma-2" type="submit">Update</v-btn>
  </v-form>
</template>
<script>
import { marked } from "marked";
import { debounce } from "lodash-es";
import { mapGetters } from "vuex";
export default {
  props: {
    postId: {},
  },
  data() {
    return {
      id: "",
      title: "",
      headline: "",
      input:
        "# Title \n ___\n ```python \n import pandas as pd \n\n df = pd.read_csv('data.csv')\n```",
    };
  },
  computed: {
    ...mapGetters({ blogPost: "blog/getPostById" }),

    output() {
      return marked(this.input);
    },
    update() {
      return debounce((e) => {
        this.input = e.target.value;
      }, 100);
    },
  },

  methods: {
    onSubmit() {
    //   console.log("submit", this.title, this.headline, this.input);
      if (this.title != "" && this.headline != "" && this.input != "") {
        this.$store.dispatch("blog/updatePost", {
          url: `/post/update/${this.id}`,
          body: {
            title: this.title,
            headline: this.headline,
            post: this.input,
          },
        });

        this.$router.push({ name: "allPosts" });
      } else {
        console.warn("Complete all fields");
      }
    },
  },

  mounted() {
    const post = this.blogPost(this.postId);
    console.log(post);
    this.id = post._id;
    this.title = post.title;
    this.input = post.post;
  },
};
</script>
<style scoped>

</style>