<template>
    <v-container fluid>


        <v-row class="d-flex align-center flex-sm-row flex-column">
            <v-col>
                <v-card>
                    <card-title title='Search Default Index'></card-title>
                    <v-card-text>
                        <v-row class="d-flex align-center flex-sm-row flex-column ">
                            <v-col>
                                <v-text-field type='string' name="Search" label="Search" variant='outlined'
                                    v-model="vars.query" class='input-text-color' />
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-row>
            <v-col cols="12" class="mx-auto">
                <v-card>
                    <!-- <v-form @submit.prevent="sendMessage()"> -->
                    <v-form>
                        <!-- <v-textarea row-height="15" rows="1" name="input-7-1" label="Search.." v-model="searchText" hint="Search 4chan post"> </v-textarea> -->
                        <v-card-actions>
                            <br />
                            <!-- <v-btn color='primary' @click="sendMessage()">Search</v-btn> -->
                        </v-card-actions>
                    </v-form>
                </v-card>
                <div v-if="comp">
                    <div v-for="com in comp" :key="com">
                        <v-card>
                            {{ com.com }}
                        </v-card>
                    </div>
                </div>

                <v-card v-if="comp">
                    <v-expansion-panels>
                        <v-expansion-panel v-for="post of comp" :key="post" :title="post.com">

                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script setup>
import { ref, reactive } from 'vue'
import gql from 'graphql-tag'
import { useQuery } from '@vue/apollo-composable';
import { watchEffect, computed } from 'vue';
const Q = gql`
query defaultSearch($query: String!, $path: String!, $limit: Int = 2) {
    searchDefaultIndex(query: $query, path: $path, limit: $limit) {
        com
    }
}
`
const vars = reactive({
    query: "kanye",
    path: 'com',
    limit: 100
})
const { result, loading, error } = useQuery(Q, vars);
const comp = computed(() => result.value?.searchDefaultIndex ?? [])

watchEffect(() => {
    console.log(comp.value)
})



</script>