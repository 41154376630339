<template>
<div class='expansion-panel'>
  
    <!-- <v-expansion-panels> -->
      <v-expansion-panels
        v-for="item in data.entries"
        :key="item.id"
      >
      <v-expansion-panel >
      <v-expansion-panel-title>

       <v-row no-gutters>
          <v-col cols="8" class="d-flex justify-start">
              {{ item.title }}
          </v-col>
          <v-col cols="4" class="text--secondary justify-end d-flex">
          {{ item.published }}
          </v-col>
        </v-row>

        </v-expansion-panel-title>
        
        <v-expansion-panel-text>
            <a target="_blank" :href="item.link">
              {{ item.title }}
            </a>
          <!-- <p v-html='item.summary_detail.value'> -->
          <!-- </p> -->
          <!-- {{ item.summary_detail.value}} -->
        </v-expansion-panel-text>

      </v-expansion-panel>
    </v-expansion-panels>


</div>
</template>
<script>
import { mapActions } from "vuex";
// import { mapGetters } from "vuex";
// import { mapMutations } from "vuex";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      data: [],
    };
  },

  // watch: {
    // async page(newVal, oldVal) {
    //   console.log(newVal, oldVal)
    //   // this.createFarms();
    //   // await this.createFarms({limit: this.select, skip: this.select + this.select});
    // },
    //  select(newVal) {
    //   this.SET_LIMIT(newVal);
    //   this.createFarms();
    // }
  // },

  methods: {
    ...mapActions({
      getRequest: "getById",
    }),


    // ...mapMutations({
    //   SET_LIMIT: "analytics/SET_LIMIT",
    // }),
  },

  computed: {
    // ...mapGetters({
    //   union: "analytics/farmGetter",
    //   totalPages: "analytics/pages",
    // }),

    // getUnion() {
      // return this.createFarms();
    // }

  },

  async mounted() {
    this.data = await this.getRequest('/rss/feed/all')
    console.log(this.data);
    // await this.createFarms({limit: this.select, skip: this.select});
    // await this.createFarms();
    // await this.pageLength();

    // this.tableHeaders = Object.keys(this.union[0]);
    // // console.log('a: -->>', this.tableHeaders);
    // this.tableHeaders.forEach((i) => {
    //   const item = {
    //     title: i,
    //     key: i,
    //   };
    //   this.tableCols.push(Object.create(item));
      // console.log(this.tableCols);
    // });
  },
});
</script>

    