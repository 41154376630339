<template>
  <v-container>
    <v-row>
      <v-col>
        <card-main>

        <router-view v-slot="{ Component }">
          <transition>
            <component :is="Component" />
          </transition>
        </router-view>

        </card-main>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {},
};
</script>
